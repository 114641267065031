import PublicLayout from "./PublicLayout";

function withLayout(Component: any) {
  // eslint-disable-next-line react/display-name
  return (props: any) => (
    <PublicLayout>
      <Component {...props} />
    </PublicLayout>
  );
}

export default withLayout;
