import React, { useEffect, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../user/UserContext";
import Dropdown from "../../DropDown/DropDown";
import Button from "../../Button/Button";

interface MenuItem {
  name: string;
  to: string;
  dropdown?: boolean;
  dropdownItems?: MenuItem[];
}

function Navigation() {
  const { store, setStore } = useStore();
  const navigate = useNavigate();

  const isAuthenticated = Boolean(store);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileMenuDropdownOpen, setMobileMenuDropdownOpen] = useState<number | null>(null);

  useEffect(() => {
    if (isUnauthorisedInUrl()) {
      setStore(null);
      return;
    }

    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  function isUnauthorisedInUrl() {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.has("unauthorised");
  }

  const menuItems: MenuItem[] = [
    { name: "Home", to: "/" },
    {
      name: "Features",
      to: "#",
      dropdown: true,
      dropdownItems: [
        { name: "Properties Management", to: "/features/properties-management" },
        { name: "Rental Applications", to: "/features/rental-application" },
        // { name: "Rental Applications & Tenant Screening", to: "/features/tenants-screening" },
        { name: "Landlords & Owners Portal", to: "/features/landlord-portal" },
        { name: "Property Maintenance", to: "/features/property-maintenance" },
        { name: "Reporting & Analytics", to: "/features/property-reports" },
        { name: "Tenants Portal", to: "/features/tenants-portal" }
      ]
    },
    { name: "About Us", to: "/company" },
    { name: "Pricing", to: "/pricing" },
    { name: "Contact", to: "/contact" },
    {
      name: "Resources",
      to: "#",
      dropdown: true,
      dropdownItems: [
        { name: "Blog", to: "/resources/blog" },
        { name: "FAQS", to: "/faqs" },
        { name: "Guides", to: "/resources/guides" },
        // { name: "Demo Video", to: "/resources/demo-video" },
        { name: "Support", to: "/resources/support" }
      ]
    }
  ];

  const toggleMenu = useCallback(() => {
    setMobileMenuOpen((prev) => !prev);
  }, []);

  const handleMobileMenuDropdownToggle = useCallback((index: number) => {
    setMobileMenuDropdownOpen((prevState) => (prevState === index ? null : index));
  }, []);

  const renderMenuIcon = useCallback(() => {
    return mobileMenuOpen ? faTimes : faBars;
  }, [mobileMenuOpen]);

  return (
    <div className="flex w-full items-center bg-white">
      <header className="fixed inset-x-0 top-0 z-50 w-full bg-gray-50 px-4">
        <nav className="mx-auto flex max-w-[1240px] items-center justify-between py-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">RentalEase</span>
              <div className="flex items-center justify-center">
                <img src={process.env.PUBLIC_URL + "/img/logos/logo-2.png"} alt="Logo" className="mr-2 w-12" />
                <span className="font-bold">RentalEase</span>
              </div>
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={toggleMenu}
              aria-expanded={mobileMenuOpen}
              aria-controls="mobile-menu"
            >
              <span className="sr-only">Open menu</span>
              <FontAwesomeIcon className="h-6 w-6" aria-hidden="true" icon={renderMenuIcon()} />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {menuItems.map((item) => (
              <div key={item.name} className="relative">
                {item.dropdown ? (
                  <Dropdown name={item.name} items={item.dropdownItems!} />
                ) : (
                  <Link key={item.name} to={item.to} className="text-base font-bold leading-6 text-gray-900 hover:text-hover">
                    {item.name}
                  </Link>
                )}
              </div>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Button
              onClick={() => navigate("/login")}
              text="Login"
              className="mx-2 rounded-md text-base font-bold leading-6 hover:bg-hover"
            />
            <Button
              onClick={() => navigate("/register")}
              text="Sign Up"
              className="mx-2 rounded-md text-base font-bold leading-6 hover:bg-hover"
            />
          </div>
        </nav>
        {mobileMenuOpen && (
          <div className="border-t border-gray-200 bg-gray-50 lg:hidden" id="mobile-menu">
            <div className="flex flex-col text-start">
              {menuItems.map((item, index) => (
                <div key={item.name} className="relative">
                  {item.dropdown ? (
                    <Dropdown
                      name={item.name}
                      items={item.dropdownItems!}
                      isMobile={true}
                      isOpen={mobileMenuDropdownOpen === index}
                      toggleDropdown={() => handleMobileMenuDropdownToggle(index)}
                    />
                  ) : (
                    <Link to={item.to} className="block px-4 py-2 text-lg font-bold text-gray-900 hover:bg-gray-200">
                      {item.name}
                    </Link>
                  )}
                </div>
              ))}
              <Link to="/login" className="block px-4 py-2 text-lg font-bold text-gray-900 hover:bg-gray-200">
                Log in
              </Link>
              <Link to="/register" className="block px-4 py-2 text-lg font-bold text-gray-900 hover:bg-gray-200">
                Sign Up
              </Link>
            </div>
          </div>
        )}
      </header>
    </div>
  );
}

export default Navigation;
