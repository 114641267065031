import React from "react";
import withPublicLayout from "../../components/public/withPublicLayout";
import Support from "../../components/Support/Support";

function PublicSupportPage() {
  function render() {
    return (
      // <section className="mt-32 flex flex-col h-screen w-full items-center justify-center bg-gradient-to-r from-zinc-200 from-30% to-blue-50 to-70% py-[10rem] md:mt-12">
      //   <div className="w-full rounded-lg bg-white bg-opacity-95 shadow-lg md:w-3/4 lg:w-2/3 xl:w-1/2">
      //     <div className="p-8">
      //       <div className="mb-8 flex flex-col items-center justify-center">
      //         <img src={process.env.PUBLIC_URL + "/img/logos/logo-2.png"} alt="Logo" className="m-0 w-10" />
      //         <h2 className="mb-2 mt-4 text-center text-2xl font-semibold">Create support ticket</h2>
      //       </div>
      <Support />
      //     </div>
      //   </div>
      // </section>
    );
  }

  return render();
}

export default withPublicLayout(PublicSupportPage);
