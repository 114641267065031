// import LabelWithValue from "../Input/LabelWithValue";
import ReactSelect from "react-select";
import { useEffect, useState } from "react";
import Input from "../Input/Input";

interface IProps {
  label?: string;
  name: string;
  value: any;
  custom_value?: any;
  onChange: any;
  options: any;
  readOnly?: boolean;
  className?: string;
  height?: string;
  showRequired?: boolean;
  register?: any;
  error?: string;
}
function Select(props: IProps) {
  const { label, name, options, value, readOnly, custom_value, className, height, showRequired, register, error } = props;

  const [menuPortalTarget, setMenuPortalTarget] = useState<any>(null);
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);

  useEffect(() => {
    if (register) {
      register.validate(value);
    }
  }, []);

  useEffect(() => {
    setMenuPortalTarget(document.body);
  }, []);

  function onChange(e: any) {
    setIsInitialRender(false);
    props.onChange(e);
    if (register) {
      register.validate(e.target.value);
    }
  }

  function render() {
    const _options = options?.map((option: any) => ({
      value: option.id ?? option.value,
      label: option.name ?? option.label
    }));

    const customStyles = {
      control: (provided: any) => ({
        ...provided,
        backgroundColor: "white",
        borderColor: "gray-300",
        hoverColor: "#f2f2f2",
        cursor: "pointer",
        minHeight: height,
        height: height
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#f2f2f2" : "white",
        color: "black"
      }),
      menu: (provided: any) => ({
        ...provided,
        zIndex: 9999
      }),
      menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 9999
      })
    };

    return (
      <>
        <div className={readOnly ? "" : "px-2"}>
          <label htmlFor={name} className="text-sm font-semibold">
            {label}
            {showRequired && " *"}
          </label>
          {!readOnly ? (
            <ReactSelect
              id={name}
              className={`w-full rounded border focus:outline-none focus:ring-2 ${className} ${!isInitialRender && error ? "border-danger focus:ring-danger" : "border-gray-300 focus:ring-secondary"}`}
              value={_options?.find((option: any) => option.value === value)}
              onChange={(_value) => onChange({ target: { name: name, value: _value?.value } })}
              options={_options}
              styles={customStyles}
              menuPortalTarget={menuPortalTarget}
              menuPosition="fixed"
              menuPlacement="auto"
            />
          ) : (
            <Input name="" type="text" value={custom_value ? custom_value : value} readOnly={readOnly} onChange={() => {}} />
          )}
        </div>
        {!isInitialRender && error && <span className="text-danger text-xs">{error}</span>}
      </>
    );
  }
  return render();
}

export default Select;
