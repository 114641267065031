import { createContext, useContext, useState } from "react";
import Toast from "./Toast";
import * as utils from "../../utils/utils";

interface ToastProps {
  toastID: string;
  message: string;
  type: string;
  onClose?: any;
}

// Create a context to manage toasts
export const ToastContext = createContext<any>(null);

export function useToast() {
  return useContext(ToastContext);
}

// Toast provider to wrap around your app
export function ToastProvider({ children }: any) {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  function showToast(message: string, type: string) {
    const toastID = utils.generateID(); // Generate a unique ID for the toast
    setToasts((prevToasts) => [...prevToasts, { toastID, message, type }]);

    // Auto-remove the toast after 3 seconds
    setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.toastID !== toastID));
    }, 3000);
  }

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <div className="toast-container">
        {toasts.map((toast) => (
          <Toast key={toast.toastID} {...toast} />
        ))}
      </div>
    </ToastContext.Provider>
  );
}
