import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import Icon from "../Icon/Icon";
import Loader from "../Loader/Loader";

interface IProps {
  type?: "submit" | "reset" | "button" | undefined;
  text?: string;
  icon?: any;
  iconClass?: string;
  iconSize?: SizeProp;
  secondary?: boolean;
  red?: boolean;
  green?: boolean;
  border?: boolean;
  onClick?: any;
  noPadding?: boolean;
  noPaddingIcon?: boolean;
  noBold?: boolean;
  className?: string;
  isDisabled?: boolean;
  noHover?: boolean;
  roundMd?: boolean;
  isLoading?: boolean;
}
function Button(props: IProps) {
  const {
    type,
    text,
    icon,
    iconClass,
    iconSize,
    secondary,
    border,
    noPadding,
    noPaddingIcon,
    className,
    isDisabled,
    noBold,
    red,
    green,
    noHover,
    roundMd,
    isLoading
  } = props;

  const handleClick = (e: any) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  function renderIcon() {
    return <Icon name={icon} className={"h-3 w-3 " + iconClass} size={iconSize ?? "sm"} />;
  }

  function render() {
    return (
      <>
        {isLoading ? (
          <Loader noPadding />
        ) : (
          <button
            className={
              "rounded-full " +
              (noPaddingIcon ? " " : " h-12 md:h-9 ") +
              // (secondary || red ? " " : "text-white bg-primary ") +
              (red ? "text-black bg-red-500 " : " ") +
              (green ? "text-black bg-success " : " ") +
              (border ? "border border-black " : " ") +
              (noPadding ? " " : " px-4 ") +
              (noBold ? "" : "font-bold  ") +
              (isDisabled
                ? "text-gray-300 cursor-not-allowed " + (secondary || red ? " " : "text-white bg-hover ")
                : secondary || red
                  ? " "
                  : "text-white bg-primary ") +
              (noHover ? "" : "hover:bg-hover ") +
              (roundMd ? " mx-1 md:h-10 md:w-32 h-8 w-full max-sm:rounded-md px-3 max-sm:text-sm shadow-sm hover:bg-hover" : "") +
              (className ? " " + className + " " : " ")
            }
            type={type ? type : undefined}
            onClick={handleClick}
            disabled={isDisabled}
            aria-label={text}
          >
            <div className="flex flex-row justify-center items-center">
              {icon && renderIcon()}
              {text}
            </div>
          </button>
        )}
      </>
    );
  }
  return render();
}

export default Button;
