import React, { useEffect, useState } from "react";
import Icon from "../Icon/Icon";

interface IProps {
  toastID: string;
  message: string;
  type: string;
  onClose?: any;
}

function Toast(props: IProps) {
  const { toastID, message, type } = props;

  const [showToast, setShowToast] = useState<boolean>(false);

  // Determine the background color based on the toast type
  const bgColor = type === "success" ? "bg-green-200" : "bg-red-200";
  // Determine the text color based on the toast type
  const textColor = type === "success" ? "text-green-700" : "text-red-700";

  const icon = type === "success" ? "fa-circle-check" : type === "danger" ? "fa-exclamation" : null;

  useEffect(() => {
    if (toastID) {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    }
  }, [toastID]);

  return (
    <>
      {showToast && (
        <div
          className={`fixed top-0 mb-4 mt-2 flex justify-center rounded p-4 ${bgColor} ${textColor} translate-x-0 transform transition-transform duration-500`}
          style={{ left: "50%", transform: "translateX(-50%)", zIndex: 9999 }}
        >
          <div className="flex flex-row">
            {icon && <Icon name={icon} className="p-1" />}
            <div>{message}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default Toast;
