import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTiktok, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import Button from "../../Button/Button";
import Input from "../../Input/Input";

const Footer = () => {
  return (
    <footer className="bg-footerC text-dark-300 w-full px-4 py-8">
      <div className="mx-auto grid max-w-[1240px] grid-cols-2 border-b-2 border-gray-600 py-8 md:grid-cols-4">
        <div>
          <h6 className="pt-2 font-bold uppercase">RentalEase</h6>
          <ul>
            <li className="py-1">
              <Link to="/company">About</Link>
            </li>
            <li className="py-1">
              <Link to="/pricing">Pricing</Link>
            </li>
            <li className="py-1">
              <Link to="/faqs">FAQs</Link>
            </li>
            <li className="py-1">
              <Link to="/career">Careers</Link>
            </li>
            <li className="py-1">
              <Link to="/partners">Partners</Link>
            </li>
          </ul>
        </div>
        <div>
          <h6 className="pt-2 font-bold uppercase">Features</h6>
          <ul>
            <li className="py-1">
              <Link to="/features/properties-management">Properties Management</Link>
            </li>
            {/* <li className="py-1">
              <Link to="/features/tenants-screening">Rental Applications & Tenant Screening</Link>
            </li> */}
            <li className="py-1">
              <Link to="/features/rental-applications">Rental Applications & Tenant Screening</Link>
            </li>
            <li className="py-1">
              <Link to="/features/landlord-portal">Landlords & Owners Portal</Link>
            </li>
            <li className="py-1">
              <Link to="/features/property-maintenance">Property Maintenance</Link>
            </li>
            <li className="py-1">
              <Link to="/features/property-reports">Reporting & Analytics</Link>
            </li>
            <li className="py-1">
              <Link to="/features/tenants-portal">Tenants Portal</Link>
            </li>
          </ul>
        </div>
        <div>
          <h6 className="pt-2 font-bold uppercase">Resources</h6>
          <ul>
            <li className="py-1">
              <Link to="/resources/blog">Blog</Link>
            </li>
            <li className="py-1">
              <Link to="/resources/guides">Guides</Link>
            </li>
            {/* <li className="py-1">
              <Link to="/resources/demo-video">Demo Video</Link>
            </li> */}
            <li className="py-1">
              <Link to="/resources/support">Support</Link>
            </li>
          </ul>
        </div>
        <div className="pt-8 md:pt-2">
          <p className="font-bold uppercase">Subscribe to our newsletter</p>
          <p className="py-4">The latest news, articles, and resources, sent to your inbox weekly.</p>
          <form className="text-center sm:flex-row">
            <Input placeholder="Enter name" label="" name="newsletter_name" type="text" value={""} onChange={() => {}} />
            <Input placeholder="Enter email" label="" name="newsletter_email" type="email" value={""} onChange={() => {}} />
            <Button text="Subscribe" className="my-4 pt-0" />
          </form>
        </div>
      </div>

      <div className="text-dark-500 mx-auto flex max-w-[1240px] flex-col justify-between px-2 py-4 text-center sm:flex-row">
        <p className="py-4">2024 RentalEase, LLC. All rights reserved</p>
        <div className="py-4">
          <Link to="/legal/privacy" className="mr-4">
            Privacy Policy
          </Link>
          <Link to="/legal/terms-conditions" className="mr-4">
            Terms & Conditions
          </Link>
          {/* <Link to="/sitemap" className="mr-4">
            Sitemap
          </Link> */}
        </div>
        <div className="flex justify-between pt-4 text-2xl sm:w-[300px]">
          <FontAwesomeIcon icon={faTwitter} aria-label="Twitter" />
          <FontAwesomeIcon icon={faInstagram} aria-label="Instagram" />
          <FontAwesomeIcon icon={faTiktok} aria-label="TikTok" />
          <FontAwesomeIcon icon={faFacebook} aria-label="Facebook" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
