import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faBed,
  faBuilding,
  faCity,
  faCode,
  faDollarSign,
  faGear,
  faHome,
  faPeopleGroup,
  faPerson,
  faStickyNote,
  faUser,
  faPlus,
  faTrash,
  faUserSecret,
  faTimes,
  faExclamationCircle,
  faRightFromBracket,
  faFile,
  faDownload,
  faCopy,
  faArrowUpRightFromSquare,
  faPencil,
  faBars,
  faGears,
  faRefresh,
  faArrowUp,
  faMoneyBill,
  faMessage,
  faPaperPlane,
  faThumbsUp,
  faUserTie,
  faBuildingUser,
  faFileWord,
  faHeadset,
  faStar,
  faEarListen,
  faFileExcel,
  faFilePdf,
  faBackward,
  faFlag,
  faCalendarAlt,
  faEye,
  faHeading,
  faImages,
  faCircleCheck,
  faSortUp,
  faSortDown,
  faFileImport,
  faBell,
  faAnglesLeft,
  faTriangleExclamation,
  faCircleExclamation
} from "@fortawesome/free-solid-svg-icons";

export const icons: { [key: string]: IconDefinition } = {
  "fa-bed": faBed,
  "fa-building": faBuilding,
  "fa-city": faCity,
  "fa-code": faCode,
  "fa-dollar-sign": faDollarSign,
  "fa-gear": faGear,
  "fa-home": faHome,
  "fa-people-group": faPeopleGroup,
  "fa-person": faPerson,
  "fa-sticky-note": faStickyNote,
  "fa-user": faUser,
  "fa-user-tie": faUserTie,
  "fa-building-user": faBuildingUser,
  "fa-plus": faPlus,
  "fa-times": faTimes,
  "fa-bars": faBars,
  "fa-trash": faTrash,
  "fa-user-secret": faUserSecret,
  "fa-exclamation": faExclamationCircle,
  logout: faRightFromBracket,
  "fa-file": faFile,
  "fa-file-word": faFileWord,
  "fa-download": faDownload,
  "fa-copy": faCopy,
  "fa-pencil": faPencil,
  "fa-open-new": faArrowUpRightFromSquare,
  user: faUser,
  "fa-gears": faGears,
  "fa-refresh": faRefresh,
  "fa-arrow-up": faArrowUp,
  "fa-money": faMoneyBill,
  "fa-message": faMessage,
  "fa-paper-plane": faPaperPlane,
  "fa-thumbs-up": faThumbsUp,
  "fa-headset": faHeadset,
  "fa-star": faStar,
  "fa-ear-listen": faEarListen,
  "fa-FileExcel": faFileExcel,
  "fa-FilePdf": faFilePdf,
  "fa-backward": faBackward,
  "fa-flag": faFlag,
  "fa-calendar-alt": faCalendarAlt,
  "fa-eye": faEye,
  "fa-heading": faHeading,
  "fa-images": faImages,
  "fa-circle-check": faCircleCheck,
  "fa-sort-up": faSortUp,
  "fa-sort-down": faSortDown,
  "fa-file-import": faFileImport,
  "fa-bell": faBell,
  "fa-angles-left": faAnglesLeft,
  "fa-triangle-exclamation": faTriangleExclamation,
  "fa-circle-exclamation": faCircleExclamation
};
