import { ReactNode } from "react";
import Navigation from "./navbar/navbar";
import Footer from "./Footer/Footer";

interface LayoutProps {
  children: ReactNode;
}
function PublicLayout({ children }: LayoutProps) {
  function render() {
    return (
      <div>
        <div className="">
          <Navigation />
          <div className="">{children}</div>
          <Footer />
        </div>
      </div>
    );
  }

  return render();
}

export default PublicLayout;
