import React, { useEffect, useState } from "react";
import { useToast } from "../Toast/ToastContext";
import * as utils from "../../utils/utils";
import Input from "../Input/Input";
import Loader from "../Loader/Loader";
import Button from "../Button/Button";
import { useStore } from "../user/UserContext";
import Select from "../Select/Select";
import { SUPPORT_STATUS } from "../../utils/constants";
import { getStatusClassName, titleCase } from "../../utils/utils";

interface IProps {
  support_id?: any;
  className?: string;
  isEditing?: boolean;
  onSave?: any;
}

function Support(props: IProps) {
  const { support_id, className, isEditing } = props;
  const { store } = useStore();
  const [support, setSupport] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSupport, setIsLoadingSupport] = useState<boolean>(false);
  const [hasCreated, setHasCreated] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const showToast = useToast();

  let user_id: any = null;

  if (utils.isUserAccountAdmin(store)) {
    user_id = utils.user(store)?.id;
  } else if (utils.isUserAccountUser(store)) {
    user_id = utils.user(store)?.agency_id;
  }

  useEffect(() => {
    if (support_id && support_id !== "new") {
      fetchSupport();
    }
  }, []);

  async function fetchSupport() {
    setIsLoadingSupport(true);
    const result = await utils.request(store, "support/" + support_id, "GET", {});
    if (result.ok) {
      setSupport(result.data);
    } else {
      setError(result.error);
    }
    setIsLoadingSupport(false);
  }

  async function save() {
    setIsLoading(true);
    if (user_id) {
      support.user_id = user_id;
      support.name = utils.user(store)?.name;
      support.surname = utils.user(store)?.surname;
      support.email = utils.user(store)?.email;
      support.phone = utils.user(store)?.phone;
      support.company = utils.user(store)?.company_name;
    }
    const result = await utils.request(null, "support", "POST", support);
    if (result.ok) {
      showToast("Thank you! Our team is reviewing your request.", "success");
      setHasCreated(true);
      setSupport(null);
    } else {
      setError(result?.error);
    }
    setIsLoading(false);
  }

  async function update() {
    setIsLoading(true);
    const result = await utils.request(null, "support/" + support_id, "PUT", support);
    if (result.ok) {
      showToast("Support ticket updated successfully.", "success");
      if (props.onSave) {
        props.onSave();
      }
    } else {
      setError(result?.error);
    }
    setIsLoading(false);
  }

  function isFormValid() {
    if (support_id === "new") {
      return support?.description;
    }
    return support?.name && support?.surname && support?.phone && support?.email && support?.description;
  }

  function render() {
    if (isLoadingSupport) {
      return <Loader />;
    }

    return (
      <section className="flex flex-col lg:flex-row h-auto lg:h-screen w-full">
        {!user_id && (
          <div className="hidden lg:flex w-full lg:w-1/2 text-white flex-col justify-center p-8 bg-gradient-to-r from-primary to-secondary">
            <div className="max-w-md mx-auto">
              <h2 className="text-4xl font-bold mb-6">World-Class Support</h2>
              <p className="text-lg mb-6 text-justify">
                Our dedicated team is here to assist you with any inquiries or issues. We pride ourselves on delivering top-notch support to
                ensure your experience is smooth and successful.
              </p>
              <ul className="list-disc ml-4 mb-6 space-y-2">
                <li>Responsive and friendly assistance</li>
                <li>Comprehensive solutions for your needs</li>
                <li>Quick resolution of issues</li>
              </ul>
              <p className="text-sm mb-6">Contact us anytime, and we will help you every step of the way.</p>
              <p className="text-xs">*At RentalEase your satisfaction is our top priority.</p>
            </div>
          </div>
        )}

        <div className="flex w-full lg:w-1/2 flex-col justify-center px-6 lg:px-12 py-8 mt-12 lg:mt-0 bg-gray-100">
          <div className="max-w-md mx-auto">
            {error && utils.generateErrorMessage(error, "")}
            {hasCreated && (
              <div className="flex items-center justify-center my-2">
                {utils.generateMessage("Your support ticket has been created. We're working on it!", "")}
              </div>
            )}

            {!user_id && (
              <div className="mb-4 flex flex-col items-center justify-center">
                <div className="w-16 h-auto">
                  <img src={process.env.PUBLIC_URL + "/img/logos/logo-2.png"} alt="Logo" className="max-w-full h-auto" />
                </div>
                <h2 className="mb-2 mt-2 text-2xl font-semibold">Support Ticket</h2>
              </div>
            )}

            <div className={className ? className : ""}>
              {support_id && support_id !== "new" && (
                <div className="grid grid-cols-2 mb-4">
                  <Select
                    name="Status"
                    label="Ticket Status"
                    value={support?.status}
                    onChange={(e: any) => setSupport({ ...support, status: e.target.value })}
                    options={SUPPORT_STATUS}
                    readOnly={!isEditing}
                    custom_value={<div className={getStatusClassName(support?.status)}>{titleCase(support?.status)}</div>}
                    className="col-span-2"
                  />
                </div>
              )}

              {!user_id && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <Input
                    label="First Name"
                    name="name"
                    onChange={(e: any) => setSupport({ ...support, name: e.target.value })}
                    type="text"
                    value={support?.name}
                    readOnly={support_id && !isEditing}
                  />
                  <Input
                    label="Last Name"
                    name="surname"
                    onChange={(e: any) => setSupport({ ...support, surname: e.target.value })}
                    type="text"
                    value={support?.surname}
                    readOnly={support_id && !isEditing}
                  />
                </div>
              )}

              {!user_id && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <Input
                    label="Phone"
                    name="phone"
                    onChange={(e: any) => setSupport({ ...support, phone: e.target.value })}
                    type="text"
                    value={support?.phone}
                    readOnly={support_id && !isEditing}
                  />
                  <Input
                    label="Email"
                    name="email"
                    onChange={(e: any) => setSupport({ ...support, email: e.target.value })}
                    type="email"
                    value={support?.email}
                    readOnly={support_id && !isEditing}
                  />
                </div>
              )}

              {!user_id && (
                <div className="mb-4">
                  <Input
                    label="Company"
                    name="company"
                    placeholder="Enter your company"
                    onChange={(e: any) => setSupport({ ...support, company: e.target.value })}
                    type="text"
                    value={support?.company}
                    readOnly={support_id && !isEditing}
                  />
                </div>
              )}

              <div className="mb-4">
                <label htmlFor="description" className="mb-2 block text-sm font-bold text-gray-700">
                  Message
                </label>
                <textarea
                  id="description"
                  name="description"
                  placeholder="Describe your issue"
                  value={support?.description}
                  onChange={(e: any) => setSupport({ ...support, description: e.target.value })}
                  className="mt-1 w-full rounded border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  readOnly={support_id && !isEditing}
                />
              </div>

              <div className="flex justify-end">
                {isLoading ? (
                  <Loader noPadding />
                ) : (
                  <>
                    {!support_id || support_id === "new" ? (
                      <Button text="Create" onClick={save} isDisabled={!isFormValid()} />
                    ) : (
                      <Button text="Save" onClick={update} isDisabled={!isEditing} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return render();
}

export default Support;
