const env = process.env.REACT_APP_ENV;

let baseURL;

switch (env) {
  case "development":
    baseURL = process.env.REACT_APP_BASE_URL_DEV;
    break;
  case "staging":
    baseURL = process.env.REACT_APP_BASE_URL_STAGING;
    break;
  case "production":
    baseURL = process.env.REACT_APP_BASE_URL_PRODUCTION;
    break;

  default:
    baseURL = process.env.REACT_APP_BASE_URL_LOCAL;
}

export default {
  env: env,
  baseUrl: baseURL
};
