import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface MenuItem {
  name: string;
  to: string;
}

interface DropdownProps {
  name: string;
  items: MenuItem[];
  isMobile?: boolean;
  isOpen?: boolean;
  toggleDropdown?: () => void;
}

function Dropdown({ name, items, isMobile = false, isOpen, toggleDropdown }: DropdownProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const internalToggleDropdown = useCallback(() => {
    setDropdownOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const clickHandler = ({ target }: MouseEvent) => {
        if (!dropdownRef.current || dropdownRef.current.contains(target as Node)) return;
        setDropdownOpen(false);
      };
      document.addEventListener("click", clickHandler);
      return () => document.removeEventListener("click", clickHandler);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) {
      const keyHandler = ({ keyCode }: KeyboardEvent) => {
        if (dropdownOpen && keyCode === 27) setDropdownOpen(false);
      };
      document.addEventListener("keydown", keyHandler);
      return () => document.removeEventListener("keydown", keyHandler);
    }
  }, [dropdownOpen, isMobile]);

  const handleToggle = () => {
    if (isMobile && toggleDropdown) {
      toggleDropdown();
    } else {
      internalToggleDropdown();
    }
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setDropdownOpen(false);
    }
  };

  const openState = isMobile ? isOpen : dropdownOpen;

  return (
    <div className="relative" ref={dropdownRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <button
        className={`flex cursor-pointer items-center text-lg font-bold text-gray-900 hover:bg-gray-100 hover:text-hover focus:outline-none ${isMobile ? "w-full px-4 py-2" : ""}`}
        onClick={handleToggle}
        aria-expanded={openState}
        aria-controls={`dropdown-menu-${name}`}
      >
        {name}
        <FontAwesomeIcon icon={openState ? faChevronUp : faChevronDown} className="ml-1 text-gray-600" />
      </button>
      <AnimatePresence>
        {openState && (
          <motion.div
            id={`dropdown-menu-${name}`}
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 5 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className={`absolute z-10 mt-1 rounded-lg border border-gray-200 bg-white py-1 shadow-lg ${isMobile ? "w-full" : "w-72"}`}
            style={isMobile ? { top: "100%", left: 0, position: "static" } : { top: "calc(100% + 10px)", left: 0 }}
          >
            {items.map((item) => (
              <Link key={item.name} to={item.to} className="block px-4 py-2 text-base font-semibold hover:bg-gray-100 hover:text-hover">
                {item.name}
              </Link>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Dropdown;
