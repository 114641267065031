import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { icons } from "../../utils/icons";

interface IProps {
  message: string;
  type: string;
  icon?: string;
}
function Message(props: IProps) {
  const { message, type, icon } = props;
  const [bgColor, setBgColor] = useState<string>("");
  useEffect(() => {
    if (type === "error") {
      setBgColor("bg-red-500");
    } else if (type === "success") {
      setBgColor("bg-green-200");
    } else if (type === "impersonating") {
      setBgColor("bg-orange-500");
    }
  }, []);

  function renderIcon() {
    if (icon && icons[icon]) {
      return <FontAwesomeIcon className="pr-2" icon={icons[icon]} size="lg" />;
    }
    return null;
  }

  function render() {
    return (
      <div className={"text-black-700 mb-2 rounded bg-opacity-40 p-1 text-center " + bgColor}>
        {icon && renderIcon()}
        {message}
      </div>
    );
  }

  return render();
}

export default Message;
