// import LabelWithValue from "./LabelWithValue";

import { useEffect, useState } from "react";

interface IProps {
  label?: string;
  name: string;
  type: string;
  value: any;
  onChange: any;
  readOnly?: boolean;
  placeholder?: string;
  className?: string;
  showRequired?: boolean;
  register?: any;
  error?: string;
}
function Input(props: IProps) {
  const { label, name, value, type, readOnly, placeholder, className, showRequired, register, error } = props;
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);

  useEffect(() => {
    if (register) {
      if (isInitialRender) {
        register.validate(value);
      }
    }
  }, [value]);

  function onChange(e: any) {
    setIsInitialRender(false);
    props.onChange(e);
    if (register) {
      register.validate(e.target.value);
    }
  }

  function render() {
    // console.log(error);
    return (
      <>
        <div className="flex w-full flex-col px-2">
          <label htmlFor={name} className="text-sm font-semibold">
            {label}
            {showRequired && " *"}
          </label>
          {!readOnly && (
            <input
              type={type}
              id={name}
              name={name}
              value={value ? value : ""}
              onChange={onChange}
              placeholder={placeholder ?? ""}
              className={`mt-1 w-full rounded border px-4 py-2 focus:outline-none focus:ring-2 ${className} ${
                !isInitialRender && error ? "border-danger focus:ring-danger" : "border-gray-300 focus:ring-secondary"
              }`}
              readOnly={readOnly}
            />
          )}
          {readOnly && (
            <div className="mt-1 h-10 w-full rounded border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-secondary">
              {value}
            </div>
          )}
          {!isInitialRender && error && <span className="text-danger text-xs">{error}</span>}
        </div>
      </>
    );
  }
  return render();
}

export default Input;
