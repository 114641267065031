import "./App.css";
import Routers from "./Routers";

function App() {
  function render() {
    return (
      <>
        <Routers />
      </>
    );
  }

  return render();
}

export default App;
